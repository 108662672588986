<template>
  <li class="my-1">
    <b-link :to="to"
      ><font-awesome-icon :icon="icon" class="mr-1" />{{ title }}
    </b-link>
  </li>
</template>

<script>
export default {
  props: {
    to: String,
    icon: String,
    title: String,
  },
  name: "FooterItem",
};
</script>

<style scoped>
a:link {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #fdf8e3;
  background-color: transparent;
  text-decoration: none;
}

a:active {
  color: #fdf8e3;
  background-color: transparent;
  text-decoration: none;
}
</style>