<template>
  <header class="mb-5">
    <b-navbar toggleable="md" type="light" variant="light" class="pb-0 pt-4">
      <b-navbar-brand
        to="/"
        class="ml-3 pb-0"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <b-img
          class="header-logo"
          alt="Thorney and Wiglsey Village Car Scheme logo"
          src="../../assets/header-logo.png"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="my-2"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto mr-5">
          <header-item
            v-for="(header, index) in headers"
            :key="`header-${header.to}`"
            v-bind="getLinkAttribute(header)"
            :delay="index * 100"
            :icon="header.icon"
            :title="header.title"
          />
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import HeaderItem from "./HeaderItem.vue";
export default {
  components: { HeaderItem },
  name: "Header",
  data: () => ({
    headers: [
      {
        to: "/",
        icon: "home",
        title: "Home",
      },
      {
        href: "/about-thaw",
        icon: "car",
        title: "About THAW",
      },
      {
        to: "/drivers",
        icon: "user",
        title: "Drivers",
      },
      {
        to: "/passengers",
        icon: "house-user",
        title: "Passengers",
      },
      {
        to: "/our-area",
        icon: "map-marked",
        title: "Our Area",
      },
      {
        to: "/our-gallery",
        icon: "th",
        title: "Gallery",
      },
      {
        to: "/contact-us",
        icon: "inbox",
        title: "Contact Us",
      },
    ],
  }),
  methods: {
    getLinkAttribute(header) {
      if (header.to) {
        return { to: header.to };
      } 
      else {
        console.log(header);
        // Assuming 'a' is a URL or some other attribute you want to bind
        return { href: header.href || '#' }; // Fallback to '#' or any default value
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-logo {
  max-width: 250px;
  max-height: 95%;
}
</style>
