<template>
  <div>
    <Header />
    <div role="main" id="app">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7em;
  min-height: calc(-537px + 100vh);
}
</style>
