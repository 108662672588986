import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import("../components/home/Home.vue");
const AboutThaw = () => import("../components/about-thaw/AboutThaw.vue");
const Drivers = () => import("../components/drivers/Drivers.vue");
const Passengers = () => import("../components/passengers/Passengers.vue");
const OurArea = () => import("../components/our-area/OurArea.vue");
const Gallery = () => import("../components/gallery/Gallery.vue");
const ContactUs = () => import("../components/contact-us/ContactUs.vue");

const GoodPracticeGuide = () =>
  import("../components/useful-information/GoodPracticeGuide.vue");
const Privacy = () => import("../components/useful-information/Privacy.vue");
const TandC = () => import("../components/useful-information/TandC.vue");
import NotFound from "../components/NotFound.vue";
const Accessibility = () =>
  import("../components/useful-information/Accessibility.vue");

Vue.use(VueRouter);
export default new VueRouter({
  mode: "history",
  routes: [
    { path: "/", component: Home },
    { path: "/about-thaw", component: AboutThaw },
    { path: "/drivers", component: Drivers },
    { path: "/passengers", component: Passengers },
    { path: "/our-area", component: OurArea },
    { path: "/our-gallery", component: Gallery },
    { path: "/contact-us", component: ContactUs },
    {
      path: "/good-practice-guide-for-volunteers",
      component: GoodPracticeGuide,
    },
    { path: "/privacy-policy", component: Privacy },
    { path: "/terms-of-use", component: TandC },
    { path: "/accessibility", component: Accessibility },
    { path: "*", component: NotFound },
  ],
});
