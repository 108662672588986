import Vue from "vue";
import App from "./App.vue";
import {
  LayoutPlugin,
  CardPlugin,
  NavbarPlugin,
  FormPlugin,
  ImagePlugin,
  ButtonPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormCheckboxPlugin,
  FormTextareaPlugin,
  LinkPlugin
} from "bootstrap-vue";
[
  LayoutPlugin,
  CardPlugin,
  NavbarPlugin,
  FormPlugin,
  ImagePlugin,
  ButtonPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormCheckboxPlugin,
  FormTextareaPlugin,
  LinkPlugin
].forEach((plugin) => Vue.use(plugin));
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faCar,
  faUser,
  faHouseUser,
  faMapMarked,
  faTh,
  faInbox,
  faMobile,
  faEnvelope,
  faUserShield,
  faFileSignature,
  faThList,
  faEye,
  faUniversalAccess,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faHome,
  faCar,
  faUser,
  faHouseUser,
  faMapMarked,
  faTh,
  faInbox,
  faMobile,
  faEnvelope,
  faUserShield,
  faFileSignature,
  faThList,
  faEye,
  faUniversalAccess
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

import "./assets/css/custom-bootstrap.scss";
import "animate.css";
import { init } from "aos";
import "aos/dist/aos.css";

import router from "./routes/index.js";

new Vue({
  router,
  created() {
    init();
  },
  render: (h) => h(App),
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");
