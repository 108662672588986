<template>
  <b-container data-aos="fade-up">
    <h1>Not Found</h1>
    <p>Sorry, that page doesn't seem to exist. If you feel this is in error, please contact <a href="mailto:thwvcs@gmail.com">thwvcs@gmail.com</a></p>
  </b-container>
</template>

<script>
export default {
  name: "NotFound"
};
</script>