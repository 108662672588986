<template>
  <footer class="bg-primary mt-5">
    <b-container class="py-5 text-secondary">
      <b-row>
        <b-col md="4">
          <b-img-lazy
            class="header-logo"
            alt="Thorney and Wiglsey Village Car Scheme logo"
            src="../../assets/footer-logo.png"
          />
        </b-col>
        <b-col md="4">
          <h4>Useful Information</h4>
          <ul class="info-ref pl-0">
            <footer-item
              v-for="item in usefulItems"
              :key="`useful-item-${item.icon}`"
              :to="item.to"
              :icon="item.icon"
              :title="item.title"
            />
          </ul>
        </b-col>
        <b-col md="4">
          <h4>Get In Touch</h4>
          <ul class="info-ref pl-0">
            <li class="my-1">
              <a href="tel:+447717178800"
                ><font-awesome-icon icon="mobile" class="mr-1" />07717-17-88-00</a
              >
            </li>
            <li class="my-1">
              <a href="mailto:thwvcs@gmail.com"
                ><font-awesome-icon
                  icon="envelope"
                  class="mr-1"
                />thwvcs@gmail.com</a
              >
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import FooterItem from "./FooterItem.vue";
export default {
  components: { FooterItem },
  name: "Footer",
  data: () => ({
    usefulItems: [
      {
        to: "/",
        icon: "home",
        title: "Home",
      },
      {
        to: "/about-thaw",
        icon: "car",
        title: "About THAW",
      },
      {
        to: "/good-practice-guide-for-volunteers",
        icon: "th-list",
        title: "Good Practice Guide",
      },
      {
        to: "/privacy-policy",
        icon: "user-shield",
        title: "Privacy Policy",
      },
      {
        to: "/terms-of-use",
        icon: "file-signature",
        title: "Terms of Use",
      },
      {
        to: "/accessibility",
        icon: "universal-access",
        title: "Accessibility",
      },
      {
        to: "/contact-us",
        icon: "inbox",
        title: "Contact Us",
      },
    ],
  }),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-logo {
  max-width: 250px;
  max-height: 95%;
}
.info-ref {
  list-style-type: none;
}
a:link {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #fdf8e3;
  background-color: transparent;
  text-decoration: none;
}

a:active {
  color: #fdf8e3;
  background-color: transparent;
  text-decoration: none;
}
</style>
