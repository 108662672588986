<template>
  <b-nav-item class="mx-2" v-bind="getLinkAttribute()" data-aos="fade-down" :data-aos-delay="delay">
    <font-awesome-icon :icon="icon" class="mr-1" />
    <div class="d-inline-block d-md-none d-xl-inline-block">{{ title }}</div>
    <div class="d-none d-md-inline-block d-xl-none sr-only">{{ title }}</div>
  </b-nav-item>
</template>

<script>
export default {
  props: {
    to: String,
    href: String,
    delay: Number,
    icon: String,
    title: String
  },
  methods: {
    getLinkAttribute() {
      if (this.to) {
        return { to: this.to };
      }
      else {
        // Assuming 'a' is a URL or some other attribute you want to bind
        return { href: this.href || '#' }; // Fallback to '#' or any default value
      }
    }
  },
  name: "HeaderItem",
};
</script>